import Deck01 from "/src/assets/salesdeck/page01.svg"
import Deck02 from "/src/assets/salesdeck/page02.svg"
import Deck03 from "/src/assets/salesdeck/page03.svg"
import Deck04 from "/src/assets/salesdeck/page04.svg"
import Deck05 from "/src/assets/salesdeck/page05.svg"
import Deck06 from "/src/assets/salesdeck/page06.svg"
import Deck07 from "/src/assets/salesdeck/page07.svg"
import Deck08 from "/src/assets/salesdeck/page08.svg"
import Deck09 from "/src/assets/salesdeck/page09.svg"
import Deck10 from "/src/assets/salesdeck/page10.svg"
import Deck11 from "/src/assets/salesdeck/page11.svg"
import Deck12 from "/src/assets/salesdeck/page12.svg"
import React from "react";

export const Slides: Array<any> = [
    <Deck01 />,
    <Deck02 />,
    <Deck03 />,
    <Deck04 />,
    <Deck05 />,
    <Deck06 />,
    <Deck07 />,
    <Deck08 />,
    <Deck09 />,
    <Deck10 />,
    <Deck11 />,
    <Deck12 />,
]